import React from "react"
import "./Footer.scss"

import slayteTextBlackWhite from '../img/slayte-text-white-tm.png'

export const FooterTemplate = ({ data }) => {
  return (
    <div className="footer">
      <div className="footer-container container">
        <br />
        <br />
        <img src={slayteTextBlackWhite} alt="Slayte" width="100" />
        <br />
        <br />
        &copy; Slayte 2020
      </div>
    </div>
  )
}

const Footer = props => {
  if (!props.data) {
    return null
  }
  const data = props.data.edges[0].node.frontmatter
  return <FooterTemplate data={data} />
}

export { Footer }
