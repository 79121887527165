import React from 'react'
import { Highlight } from 'react-instantsearch-dom'
import { Link } from 'gatsby'

const collectionTitle = {
  'calls-admin': 'Calls - Admin Guide',
  'calls-reviewer': 'Calls - Reviewer Guide',
  'get-started': 'Get Started'
}

export const GuideHit = (clickHandler) => ({ hit }) => (
  <div key={hit.objectID}>
    <Link to={hit.slug} onClick={clickHandler}>
      {hit.collection && collectionTitle[hit.collection] && (
        <h5 className='collection'>
          <i className='fal fa-caret-right'></i> {collectionTitle[hit.collection]}
        </h5>
      )}
      <h4 className='title'>
        <Highlight attribute='title' hit={hit} tagName='strong' />
      </h4>
      <span className='excerpt'>
        <Highlight attribute='excerpt' hit={hit} tagName='em' />
      </span>
    </Link>
  </div>
)
