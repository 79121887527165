import React from 'react'
import classnames from 'classnames'
import { connectSearchBox } from 'react-instantsearch-dom'

export default connectSearchBox(({ refine, collapse, focus, onFocus, ...rest }) => (
  <div className="Form">
    <input
      className={classnames('Input', {
        'collapse': collapse,
        'focus': focus,
      })}
      type='text'
      placeholder='Search'
      aria-label='Search'
      onChange={e => refine(e.target.value)}
      onFocus={onFocus}
      {...rest}
    />
    <span className='SearchIcon' />
  </div>
))
