import React from "react"

import "./Navbar.scss"
import CustomLink from "./CustomLink"

export const NavbarTemplate = ({ groups } = { groups: [] }) => (
  <div className="navbar">
    <nav className="navbar-menu">
      {groups.map(({ label, articles }) => (
        <div key={label} className="navbar-folder">
          <span className="navbar-folder-label">{label}</span>
          <ul className="navbar-folder-menu">
            {articles.map(({ title, slug }) => (
              <li key={slug} className="navbar-menuItem">
                <CustomLink
                  linkType="internal"
                  linkURL={slug}
                  className="navbar-menuLink"
                >
                  {title}
                </CustomLink>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </nav>
  </div>
)

const Navbar = props => {
  if (!props.data || !props.data.groups || props.data.groups.length === 0) {
    return null
  }
  return <NavbarTemplate groups={props.data.groups} />
}

export { Navbar }
