import React, { useState, useEffect, createRef } from 'react'
import {
  InstantSearch,
  Hits,
  connectStateResults,
} from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'
import Input from './Input'
import * as HitComps from './HitComps'
import classnames from 'classnames'
import './Search.scss'

const Results = connectStateResults(
  ({ searchState: state, searchResults: res, children }) =>
    res && res.nbHits > 0 ? children : `No results for '${state.query}'`
)

const Stats = connectStateResults(
  ({ searchResults: res }) =>
    res && res.nbHits > 0 && `${res.nbHits} result${res.nbHits > 1 ? `s` : ``}`
)

const useClickOutside = (ref, handler, events) => {
  if (!events) {
    events = [`mousedown`, `touchstart`]
  }
  
  const detectClickOutside = (event) =>
    !ref.current.contains(event.target) && handler()
  
    useEffect(() => {
    for (const event of events)
      document.addEventListener(event, detectClickOutside)
    return () => {
      for (const event of events)
        document.removeEventListener(event, detectClickOutside)
    }
  })
}

export default function Search({ collapse, hitsAsGrid }) {
  const ref = createRef()
  const [query, setQuery] = useState(``)
  const [focus, setFocus] = useState(false)
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )
  useClickOutside(ref, () => setFocus(false))
  return (
    <div className='SearchRoot' ref={ref}>
      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
        onSearchStateChange={({ query }) => setQuery(query)}
      >
        <Input
          onFocus={() => setFocus(true)}
          collapse={collapse}
          focus={focus}
        />
        <div
          className={classnames('HitsWrapper', {
            'show': query.length > 0 && focus,
            'asGrid': hitsAsGrid,
          })}
        >
          <span className='Stats'><Stats /></span>
          <Results>
            <Hits hitComponent={HitComps['GuideHit'](() => setFocus(false))} />
          </Results>
        </div>
      </InstantSearch>
    </div>
  )
}
