import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

import "../styles"
import "./Layout.scss"
import { Navbar } from "../components/Navbar"
import { Footer } from "../components/Footer"
import Search from "./Search"

import imgSlayteText from '../img/slayte-text-black-tm.png'

const TemplateWrapper = ({
  footerData = null,
  navbarData = null,
  children,
}) => (
  <div>
    <Helmet>
      <html lang="en" />
      <script src="https://kit.fontawesome.com/f52da17f4d.js" crossOrigin="anonymous"></script>
    </Helmet>
    <section className="header">
      <div className="container">
        <a href="/">
          <img className="header-logo" src={imgSlayteText} alt="Slayte" />
        </a>
        <span className="header-title">Guides</span>
        <span className="header-fill">&nbsp;</span>
        <Search
          collapse={true}
        />
      </div>

    </section>
    <div className="main-wrapper container">
      <div style={{ flexBasis: 1, flexGrow: 0, flexShrink: 0 }}><Navbar data={navbarData} /></div>
      <div className="main" style={{ flexBasis: 1, flexGrow: 1, flexShrink: 0 }}>{children}</div>
    </div>
    <Footer data={footerData} />
  </div>
)

export const query = graphql`
  fragment LayoutFragment on Query {
    footerData: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "footer" } } }) {
      edges {
        node {
          id
        }
      }
    }
    navbarData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "navbar", } } }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            collection
            title
            description
            label
            folders {
              label
              group
            }
          }
        }
      }
    }
    allArticles: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "Article" } } }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          id
          fields {
            slug
            collection
          }
          frontmatter {
            index
            title
          }
        }
      }
    }
  }
`

export default TemplateWrapper
